/* eslint-disable @typescript-eslint/ban-ts-comment */

'use client';
import clsx from 'clsx';

import { mapLabelFilterProvider } from '@/lib/utils';

import { PARTNER_PROVIDER_OPTIONS } from '@/constant/data';

// @ts-ignore
const imageList = require.context(
  '/public/img/game/banner/logo-provider',
  false,
  /\.(png|jpe?g|svg)$/
);
const providerImages = imageList
  .keys()
  .map((image: any) => imageList(image))
  .map((_: any) => {
    const src = _.default?.src?.split('/');
    return src[src.length - 1].split('.')[0];
  });

const popularOrder = [
  'pgsoft',
  'evoplay', // Evolution
  'playngo',
  'pragmatic',
  'aesexy',
  'netent',
  'askmelotto', // AskMebet
];

export const RenderProviderPill = (
  gameItems: any = [],
  viewMore?: any,
  className = '',
  itemClassName = ''
) => {
  // Get the priority list based on popularOrder
  const priorityList = gameItems?.slice?.(0, popularOrder.length) || [];
  const priorityLength = Math.ceil(priorityList.length / 3);

  // Split the priority list into three parts
  const firstPriority = priorityList.slice(0, priorityLength);
  const secondPriority = priorityList.slice(priorityLength, priorityLength * 2);
  const thirdPriority = priorityList.slice(priorityLength * 2);

  // Remaining items after the priority list
  const remainingItems = gameItems?.slice(popularOrder.length) || [];

  // Split the remaining items into three parts
  const oneThirdLength = Math.ceil(remainingItems.length / 3);
  const firstThird = remainingItems.slice(0, oneThirdLength);
  const secondThird = remainingItems.slice(oneThirdLength, oneThirdLength * 2);
  const lastThird = remainingItems.slice(oneThirdLength * 2);

  // Merge priority lists with remaining parts
  const finalFirstThird = [...firstPriority, ...firstThird];
  const finalSecondThird = [...secondPriority, ...secondThird];
  const finalLastThird = [...thirdPriority, ...lastThird];

  return (
    <div className='hide-scroll-bar -ml-3 snap-x snap-mandatory overflow-x-auto px-3'>
      <div
        className={clsx(
          '-ml-3 flex w-full items-center items-center gap-1 px-3',
          className
        )}
      >
        {finalFirstThird?.map(([provider, slug]: any, index: any) => {
          return providerImages.includes(mapLabelFilterProvider(provider)) ? (
            <div
              onClick={() =>
                viewMore({
                  slug,
                  cateDetail: 'all',
                  provider,
                })
              }
              className={clsx(
                'bg-linear-15 vw:py-0 vw:rounded-[32px] vw:h-[30px] shadow-9 flex min-w-fit items-center overflow-hidden border border-[#FCFCFC]',
                itemClassName
              )}
              key={index}
            >
              <div className='vw:!text-12 vw:px-1 vw:pl-3  mr-auto min-w-fit overflow-hidden whitespace-pre capitalize text-white'>
                <div>
                  {PARTNER_PROVIDER_OPTIONS?.[`${provider}_${slug}`]?.label ||
                    PARTNER_PROVIDER_OPTIONS?.[provider]?.label ||
                    PARTNER_PROVIDER_OPTIONS?.[mapLabelFilterProvider(provider)]
                      ?.label}
                </div>
              </div>

              <div className={clsx('rounded-lg')}>
                <img
                  className='logo vw:w-[28px] vw:pr-2 ml-auto '
                  src={`/img/game/banner/logo-provider/${provider}.png`}
                  alt=''
                />
              </div>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
      <div
        className={clsx(
          '-ml-3 flex w-full items-center items-center gap-1 px-3 py-1',
          className
        )}
      >
        {finalSecondThird?.map(([provider, slug]: any, index: any) => {
          return providerImages.includes(mapLabelFilterProvider(provider)) ? (
            <div
              onClick={() =>
                viewMore({
                  slug,
                  cateDetail: 'all',
                  provider,
                })
              }
              className={clsx(
                'bg-linear-15 vw:py-0 vw:rounded-[32px] vw:h-[30px] shadow-9 flex min-w-fit items-center overflow-hidden border border-[#FCFCFC]',
                itemClassName
              )}
              key={index}
            >
              <div className='vw:!text-12 vw:px-1 vw:pl-3  mr-auto min-w-fit overflow-hidden whitespace-pre capitalize text-white'>
                <div>
                  {PARTNER_PROVIDER_OPTIONS?.[provider]?.label ||
                    PARTNER_PROVIDER_OPTIONS?.[mapLabelFilterProvider(provider)]
                      ?.label}
                </div>
              </div>

              <div className={clsx('rounded-lg')}>
                <img
                  className='logo vw:w-[28px] vw:pr-2 ml-auto '
                  src={`/img/game/banner/logo-provider/${provider}.png`}
                  alt=''
                />
              </div>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
      <div
        className={clsx(
          '-ml-3 flex w-full items-center items-center gap-1 px-3',
          className
        )}
      >
        {finalLastThird?.map(([provider, slug]: any, index: any) => {
          return providerImages.includes(mapLabelFilterProvider(provider)) ? (
            <div
              onClick={() =>
                viewMore({
                  slug,
                  cateDetail: 'all',
                  provider,
                })
              }
              className={clsx(
                'bg-linear-15 vw:py-0 vw:rounded-[32px] vw:h-[30px] shadow-9 flex min-w-fit items-center overflow-hidden border border-[#FCFCFC]',
                itemClassName
              )}
              key={index}
            >
              <div className='vw:!text-12 vw:px-1 vw:pl-3  mr-auto min-w-fit overflow-hidden whitespace-pre capitalize text-white'>
                <div>
                  {PARTNER_PROVIDER_OPTIONS?.[provider]?.label ||
                    PARTNER_PROVIDER_OPTIONS?.[mapLabelFilterProvider(provider)]
                      ?.label}
                </div>
              </div>

              <div className={clsx('rounded-lg')}>
                <img
                  className='logo vw:w-[28px] vw:pr-2 ml-auto '
                  src={`/img/game/banner/logo-provider/${provider}.png`}
                  alt=''
                />
              </div>
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
};
