/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import React from 'react';

import { RenderItemGames } from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderItemGames';

const ProviderGames: React.FC<any> = ({
  // params,
  slotJackpot = {},
  // handleClickMenu,
  data,
}: // display = '',
any) => {
  // const [display, partnerProvider]: any = params;
  // // const [selectValue, setSelectValue] = useState(partnerProvider);
  // // const { t } = useClientTranslation('HomePage');
  // const isMobile = useIsMobile();

  // const {
  //   data: games,
  //   isLoading,
  //   isValidating,
  // } = useSWR(
  //   selectValue ? '/get-games' + selectValue + display : null,
  //   () => {
  //     return getGames({
  //       limit: 100,
  //       partner: selectValue,
  //       device: isMobile ? 'mobile' : 'pc',
  //       display: hotMapping(display),
  //     });
  //   },
  //   {
  //     revalidateOnFocus: false,
  //   }
  // );
  // const data: any[] = games?.data?.items || [];

  // const goBack = () => {
  //   handleClickMenu(lobbyCateProvider[display]?.slug);
  // };

  // const loading =
  //   (Boolean(isLoading) || Boolean(isValidating)) && !games?.data?.items;
  // const options = useMemo(
  //   () =>
  //     (lobbyCateProvider?.[display]?.partner_provider || [])?.map((_: any) => ({
  //       label: (
  //         <div className='flex items-center uppercase'>
  //           <span className='mr-auto w-[70%] overflow-hidden capitalize'>
  //             {mapLabelFilterProvider(_, { isFormat: true })}
  //           </span>
  //           <div className='rounded-lg'>
  //             <img
  //               className='logo w-[22px]'
  //               src={`/img/game/banner/logo-provider/${mapLabelFilterProvider(
  //                 _
  //               )}.png`}
  //               alt=''
  //             />
  //           </div>
  //         </div>
  //       ),
  //       value: _,
  //     })),
  //   []
  // );

  return (
    <div className='px-2'>
      {/* {loading && (
        <Page
          animation={TransitionAnimation.SLIDE_UP}
          className='grid grid-cols-3 gap-2 px-2'
        >
          <PlaceHolderGameLoading
            className='vw:!h-[80px] vw:!w-[116px]'
            amount={21}
          />
        </Page>
      )}
      {!loading && !data?.length && (
        <div className='pt-6'>
          <Empty />
        </div>
      )} */}

      {RenderItemGames(data, slotJackpot)}
    </div>
  );
};

export default React.memo(ProviderGames);
