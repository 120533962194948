/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client';
import { Empty } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { getDataLocal, saveLocalByKey, setDataLocal } from '@/lib/utils';

import { openGameProvider } from '@/components/core/OpenGame';
import { useAppRouter } from '@/components/hooks/useAppRouter';
import CounterUp from '@/components/modules/CounterUp';

import { useGameModalStore } from '@/store/zustand';

const Counter = ({ countUpRef, start }: any) => {
  useEffect(() => {
    start();
  }, []);

  return (
    <span className='vw:text-8 flex min-w-fit items-center rounded-2xl'>
      <span className='text-main-pri vw:mr-[2px] vw:w-[8px] vw:h-[8px]'>
        <img className='h-full w-full' src='/img/common/b.svg' alt='currency' />
      </span>
      <span className='text-main-bg block' ref={countUpRef}></span>
    </span>
  );
};

export const hearted = 'HEARTED';
export const recentPlay = 'RECENT_PLAY';

export const RenderHotGames = ({
  gameItems = [],
  slotJackpot = {},
  type = 'scroll',
  className,
  cate = 'hot',
  loading = false,
}: // loading = false,
any) => {
  // const cate = 'hot';
  const { isLoadingGame } = useGameModalStore();
  const { router } = useAppRouter();
  const [loaded, setLoaded] = useState(false);
  const [_, setHeartBasket] = useState(
    getDataLocal(hearted)?.[`${cate}`] || []
  );

  // const gamesFilter = useMemo(
  //   () =>
  //     getDataLocal(hearted)?.[`${cate}`]?.length > 0
  //       ? sortByHearted(gameItems, getDataLocal(hearted)?.[`${cate}`])
  //       : gameItems,
  //   [cate, gameItems]
  // );

  if (loading) return null;

  return (
    <>
      {!(gameItems?.length > 0) && (
        <>
          <div className='pt-6'>
            <Empty />
          </div>
        </>
      )}
      <div
        className={clsx(
          type === 'scroll' &&
            'hide-scroll-bar -ml-3 snap-x snap-mandatory overflow-x-auto',
          'flex items-center gap-2 px-3',
          className
        )}
      >
        {gameItems?.map((_: any, index: number) => {
          const number = Number(
            slotJackpot?.[_?.partner_game_id] || _?.slotJackpot || 0
          );
          const end = Number(number || String(_?.id + '102321'));
          const start = end - 1000;

          return (
            <div
              key={_.key || index}
              onClick={async () => {
                if (isLoadingGame !== 0 && isLoadingGame !== _.id) return;
                await openGameProvider(_, router, true);
                const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds
                saveLocalByKey({
                  entryPointKey: recentPlay,
                  cate,
                  currentItem: _,
                  isRemoveable: false,
                  onSavedData: () => {
                    setDataLocal(recentPlay, {
                      ...(getDataLocal(recentPlay) || {}),
                      expiredTime: new Date().getTime() + threeDaysInMillis,
                    });
                  },
                  isCheckSaveDuplicate: true,
                });
              }}
              className='relative mb-auto'
            >
              <div
                className={clsx(
                  'vw:h-[80px] vw:min-w-[110px] relative w-full overflow-hidden rounded-[8px] border',
                  !loaded && 'loading-bg'
                )}
              >
                <div
                  className={clsx(
                    'xs:vw:w-[25px] vw:w-[35px] xs:vw:h-[20px] vw:h-[30px] vw:left-[0] vw:top-0 xs:scale-[2.3] hide-tap-highlight absolute flex scale-[3] items-center justify-center overflow-hidden'
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    saveLocalByKey({
                      entryPointKey: hearted,
                      cate,
                      currentItem: _,
                      onRemovedData: (heartedStorageRemoved) => {
                        setHeartBasket(heartedStorageRemoved);
                      },
                      onSavedData: (heartedStorageSaved) => {
                        setHeartBasket(heartedStorageSaved);
                      },
                    });
                  }}
                >
                  <div
                    className={clsx(
                      'vw:w-[20px] vw:h-[20px] heartContainer',
                      getDataLocal(hearted)?.[`${cate}`]?.find(
                        (h: any) => h?.id === _.id
                      ) && 'heartAnimated'
                    )}
                  />
                </div>
                <Image
                  priority
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  className={clsx(
                    'vw:h-[80px] vw:min-w-[110px] w-full object-cover',
                    isLoadingGame === _.id && '!brightness-50'
                  )}
                  src={_.image || _.img}
                  key={_.img}
                  width={110}
                  height={80}
                  alt={_?.name || _?.label}
                />
                {isLoadingGame === _.id && (
                  <div className='absolute left-1/2 top-[20%] -translate-x-1/2'>
                    <img
                      className='mx-auto'
                      src='/img/common/loading.svg'
                      alt='loading'
                    />
                  </div>
                )}
              </div>

              <div className='vw:mt-1 vw:text-12 vw:ml-0 line-clamp-1 text-ellipsis'>
                {_?.name || _?.label}
              </div>
              {number > 0 && (
                <div
                  className={clsx(
                    'vw:text-12 vw:min-h-[19.5px] mt-0.5 line-clamp-1 text-ellipsis'
                  )}
                >
                  <div className='text-main-pri vw:px-0.5 vw:pr-1 relative w-fit rounded-full bg-[#000000b3]'>
                    <CounterUp
                      className='vw:text-8'
                      enableScrollSpy
                      duration={3.5}
                      startOnMount
                      start={start}
                      end={end}
                      Counter={Counter}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
