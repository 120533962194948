/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { openGameProvider } from '@/components/core/OpenGame';
import { useAppRouter } from '@/components/hooks/useAppRouter';
import CounterUp from '@/components/modules/CounterUp';

const Counter = ({ countUpRef, start }: any) => {
  useEffect(() => {
    start();
  }, []);

  return (
    <span className='vw:text-11 inset-center flex min-w-fit rounded-2xl font-bold'>
      <span className='text-main-pri block' ref={countUpRef}></span>
      <span className='text-main-pri vw:ml-1'>฿</span>
    </span>
  );
};

export const RenderItemGames = (gameItems: any[] = [], slotJackpot?: any) => {
  const { router } = useAppRouter();
  const [loaded, setLoaded] = useState(false);

  return (
    <div className='xs:grid-cols-3 grid w-full grid-cols-2 gap-2'>
      {gameItems?.map((_: any, index: number) => {
        const number = Number(
          slotJackpot?.[_?.partner_game_id] || _?.slotJackpot || 0
        );
        const end = Number(number || String(_?.id + '102321'));
        const start = end - 1000;

        return (
          <div
            key={_.key || index}
            onClick={() => openGameProvider(_, router)}
            className='relative'
          >
            <div
              className={clsx(
                'hover-shine relative h-fit rounded-[8px] border',
                !loaded && 'loading-bg'
              )}
            >
              {index === 0 && (
                <img
                  className='absolute top-0 w-[7.38vw]'
                  src='/img/game/hot/hot-tag.png'
                  alt='hot-tag'
                />
              )}
              {index === 1 && (
                <img
                  className='absolute top-0 w-[7.38vw]'
                  src='/img/game/hot/new-tag.png'
                  alt='new-tag'
                />
              )}
              <Image
                onLoad={() => {
                  setLoaded(true);
                }}
                className='vw:h-[80px] w-full object-cover'
                src={_.image || _.img}
                key={_.img}
                priority
                width={116}
                height={80}
                alt={_?.name || _?.label}
              />
            </div>

            <div className='vw:mt-1 vw:text-12 vw:ml-2 line-clamp-1 text-ellipsis'>
              {_?.name || _?.label}
            </div>
            <div
              className={clsx(
                'vw:text-13 vw:ml-3 vw:min-h-[19.5px] line-clamp-1 text-ellipsis',
                'absolute right-1 top-1 z-10'
              )}
            >
              {number > 0 && (
                <div
                  className='text-main-pri vw:px-1 vw:py-0.5 relative w-fit rounded-full border border-white font-bold'
                  style={{
                    background:
                      'radial-gradient(143.73% 114.62% at 50.16% -7.95%, #D3A300 0%, #796843 0.01%, #2D2925 100%, #2D2925 100%)',
                  }}
                >
                  <CounterUp
                    className='vw:text-8'
                    enableScrollSpy
                    duration={3.5}
                    startOnMount
                    start={start}
                    end={end}
                    Counter={Counter}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
