'use client';
import React, { Fragment } from 'react';

import MatchSlider from '@/components/modules/Slider/MatchSlider';

import { RenderHotGames } from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderHotGames';
import { RenderProviderPill } from '@/app/[lng]/(home)/components/Mobile/components/Renderer/RenderProviderPill';

const HotGames: React.FC<any> = ({
  topMatch,
  hotGamesData = [],
  viewMore,
  providersWithSlugs,
}) => {
  return (
    <div className='mt-4 pb-32 pl-3'>
      {hotGamesData.map((hotGameItem: any, index?: number) => {
        if (hotGameItem.isSportGame) {
          return (
            topMatch &&
            topMatch?.length > 0 && (
              <div key={index}>
                <h2 className='border-main-secondary mb-2 mt-3 flex items-center'>
                  <span className='mr-auto flex items-center'>
                    <img
                      className='vw:w[19px] vw:h-[19px]'
                      src='/img/common/fire_flame.gif'
                      alt=''
                    />
                    <span className='vw:text-16 vw:ml-1 font-bold'>
                      Sport Games
                    </span>
                  </span>
                  <div
                    className='vw:!text-12 !text-main-secondary vw:!pr-2 flex items-center font-bold'
                    onClick={() => viewMore('sports', 'hot', '')}
                  >
                    <span className='vw:mr-0'>More</span>
                    <svg
                      className='vw:w-[16px] vw:h-[16px]'
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='17'
                      viewBox='0 0 16 17'
                      fill='none'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M7.69526 13.3047C7.43491 13.0444 7.43491 12.6223 7.69526 12.3619L11.5572 8.5L7.69526 4.63807C7.43491 4.37772 7.43491 3.95561 7.69526 3.69526C7.95561 3.43491 8.37772 3.43491 8.63807 3.69526L12.5 7.55719C13.0207 8.07789 13.0207 8.92211 12.5 9.44281L8.63807 13.3047C8.37772 13.5651 7.95561 13.5651 7.69526 13.3047Z'
                        fill='#845D00'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M3.52858 13.3047C3.26823 13.0444 3.26823 12.6223 3.52857 12.3619L7.3905 8.5L3.52857 4.63807C3.26822 4.37772 3.26822 3.95561 3.52857 3.69526C3.78892 3.43491 4.21103 3.43491 4.47138 3.69526L8.33331 7.55719C8.85401 8.07789 8.85401 8.92211 8.33331 9.44281L4.47138 13.3047C4.21103 13.5651 3.78892 13.5651 3.52858 13.3047Z'
                        fill='#845D00'
                      />
                    </svg>
                  </div>
                </h2>
                <div className='-ml-3'>
                  <MatchSlider data={topMatch} />
                </div>
              </div>
            )
          );
        }
        if (hotGameItem?.renderData?.gameItems?.length > 0)
          return (
            <>
              <div className='mb-2' key={hotGameItem.title}>
                <h2 className='border-main-secondary mb-2 mt-3 flex items-center'>
                  <span className='mr-auto flex items-center'>
                    {hotGameItem.icon && (
                      <img
                        className='vw:w[19px] vw:h-[19px]'
                        src={hotGameItem.icon}
                        alt={hotGameItem.icon}
                      />
                    )}
                    <span className='vw:text-16 vw:ml-1 font-bold'>
                      {hotGameItem.title}
                    </span>
                  </span>
                  <div
                    // href='/?id=live-casino'
                    className='vw:!text-12 !text-main-secondary vw:!pr-2 flex items-center font-bold'
                    onClick={() => viewMore(hotGameItem.viewMoreData)}
                  >
                    <span className='vw:mr-0'>More</span>
                    <svg
                      className='vw:w-[16px] vw:h-[16px]'
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='17'
                      viewBox='0 0 16 17'
                      fill='none'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M7.69526 13.3047C7.43491 13.0444 7.43491 12.6223 7.69526 12.3619L11.5572 8.5L7.69526 4.63807C7.43491 4.37772 7.43491 3.95561 7.69526 3.69526C7.95561 3.43491 8.37772 3.43491 8.63807 3.69526L12.5 7.55719C13.0207 8.07789 13.0207 8.92211 12.5 9.44281L8.63807 13.3047C8.37772 13.5651 7.95561 13.5651 7.69526 13.3047Z'
                        fill='#845D00'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M3.52858 13.3047C3.26823 13.0444 3.26823 12.6223 3.52857 12.3619L7.3905 8.5L3.52857 4.63807C3.26822 4.37772 3.26822 3.95561 3.52857 3.69526C3.78892 3.43491 4.21103 3.43491 4.47138 3.69526L8.33331 7.55719C8.85401 8.07789 8.85401 8.92211 8.33331 9.44281L4.47138 13.3047C4.21103 13.5651 3.78892 13.5651 3.52858 13.3047Z'
                        fill='#845D00'
                      />
                    </svg>
                  </div>
                </h2>
                {RenderHotGames(hotGameItem.renderData)}
              </div>
              {/* {hotGameItem.isProvider && (
            
            )} */}
            </>
          );
      })}
      <Fragment>
        <div className='border-main-secondary mb-3 mt-6 flex items-center'>
          <img
            className='vw:w[19px] vw:h-[19px]'
            src='/img/common/fire_flame.gif'
            alt=''
          />
          <span className='vw:text-16 vw:ml-1 font-bold'>Top Providers</span>
        </div>
        {RenderProviderPill(providersWithSlugs, viewMore)}
      </Fragment>
    </div>
  );
};

export default HotGames;
